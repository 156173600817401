import { useState, useRef } from "react";
import './AudioPlayer.css';
import { AudioBanner } from './AudioBanner';

const AudioPlayer = ({ mobile, url }) => {
  const audioRef = useRef(null); // Referenz für das Audio-Element
  const [audioPlay, setAudioPlay] = useState(false);

  const toggleAudioPlay = () => {
    if (!audioRef.current) return;

    if (audioPlay) {
      // Stream stoppen
      audioRef.current.pause();
      audioRef.current.src = ""; // Quelle entfernen, um Verbindung zu schließen
      setAudioPlay(false);
    } else {
      // Stream neu starten
      try {
        audioRef.current.src = url; // Quelle neu setzen
        audioRef.current.load(); // Neu laden
        audioRef.current.play(); // Abspielen starten
        setAudioPlay(true);
      } catch (err) {
        console.error("Error playing audio:", err);
      }
    }
  };

  return (
    <div className={audioPlay ? "audio-container audioOpen" : "audio-container"}>
      <div className="audioPlayer">
        <div className="playButton" onClick={toggleAudioPlay}>
          <div className={audioPlay ? "pause" : "play"} />
        </div>
      </div>
      {mobile ? (
        <div className="playInfo show">
          <AudioBanner mobile={mobile} />
        </div>
      ) : (
        audioPlay && (
          <div className={audioPlay ? "playInfo show" : "playInfo"}>
            <AudioBanner mobile={mobile} />
          </div>
        )
      )}

      {/* Verstecktes Audio-Element */}
      <audio ref={audioRef} preload="auto" style={{ display: "none" }} />
    </div>
  );
};

export default AudioPlayer;