import { useState, useEffect } from 'react';
import Weekday from '../Weekday/Weekday';
import './Calendar.css';
import moment from 'moment';

export default function Calendar({ week, error }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (week && week.length > 0) {
      setLoading(false); // Daten sind geladen
    } else {
      setLoading(true); // Noch keine Daten
    }
  }, [week]);
  
  const getCurrDate = () => {
    const thu = moment().isoWeekday(4).format('DD.MM'); // Donnerstag
    const sun = moment().isoWeekday(7).format('DD.MM'); // Sonntag
    return `${thu} - ${sun}`;
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cal-container">
      <div className="date">{getCurrDate()}</div>
      <div className="calendar">{sortWeek(week)}</div>
    </div>
  );
}

function sortWeek(week) {
  const weekComp = [];
  for (let i = 0; i < week.length; i++) {
    const daysEvents = week[i];
    weekComp.push(<Weekday key={i} daysEvents={daysEvents} day={i} />);
  }
  return weekComp;
}