import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from 'moment';

export default function useFetchAudioInfo() {
    const [loading, setLoading] = useState(true);
    const [rightNow, setRightNow] = useState(false);
    const [nextShow, setNextShow] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const controller = new AbortController();
        const fetchAudioInfo = async () => {
            try {
                setLoading(true);
                const formattedDate = moment().toISOString(); // ISO 8601 Format
                const response = await axios.get(`https://api.lasseborntraeger.de/calendarnext/${formattedDate}`, {
                    signal: controller.signal,
                });

                const shows = response.data.filter(freiOrShow);
                if (shows.length > 0) {
                    const nextShow = shows[0];
                    const isCurrentlyLive = moment().isBetween(
                        moment(nextShow.start),
                        moment(nextShow.end)
                    );
                    setNextShow(nextShow);
                    setRightNow(isCurrentlyLive);
                } else {
                    setNextShow(null);
                    setRightNow(false);
                }
                setLoading(false);
            } catch (err) {
                if (axios.isCancel(err)) {
                    console.log("Request cancelled", err);
                } else {
                    console.error("Error fetching audio info:", err);
                    setError(err);
                }
                setLoading(false);
            }
        };

        fetchAudioInfo();

        return () => {
            controller.abort();
        };
    }, []);

    const freiOrShow = (show) => {
        const day = moment(show.start).day();
        return show.summary !== 'FREI' && (day >= 1 || day === 0);
    };

    return [nextShow, rightNow, loading, error];
}