import React, { useEffect, useState } from "react";
import "./AudioPlayer.css";
import Marquee from "react-fast-marquee";
import useFetchAudioInfo from "../../Hooks/useFetchAudioInfo";
import useFetchLive from "../../Hooks/useFetchLive";

export const AudioBanner = ({ mobile }) => {
  const [nextShow, isNow, audioInfoLoading, audioInfoError] = useFetchAudioInfo();
  const [live, liveLoading, liveError] = useFetchLive();
  const [marqueeText, setMarqueeText] = useState("");

  const loading = audioInfoLoading || liveLoading;
  const error = audioInfoError || liveError;

  // Berechne den Inhalt des Banners
  const bannerContent = () => {
    if (loading) return " Loading ";
    if (error) return "Error loading data";
    if (live) return isNow ? ` ${nextShow.summary} +++ ON AIR ` : " ON AIR ";
    return " NOT ON AIR ";
  };

  const calculateMarqueeText = (content) => {
    const screenWidth = window.innerWidth;
    const minimumLength = Math.ceil(screenWidth * 1.5); // Mindestlänge: 1,5x Bildschirmbreite

    const contentLength = content.length * 8; // Schätzungsweise 8px pro Zeichen
    let repeatedText = content;

    // Wiederhole den Text, bis die Mindestlänge erreicht ist
    while (repeatedText.length * 8 < minimumLength) {
      repeatedText +=  " +++ " + content  ;
    }

    return repeatedText;
  };

  useEffect(() => {
    const content = bannerContent();
    if (content) {
      const calculatedText = calculateMarqueeText(content);
      setMarqueeText(calculatedText);
    } 
  }, [live, isNow, loading, error, nextShow]);

  return (
    <Marquee
      className="marqueeInfo"
      pauseOnHover={!live}
      play={live}
      gradient={true}
      gradientWidth={20}
      gradientColor={[148, 58, 162]}
    >
      <i>{marqueeText}</i>
    </Marquee>
  );
};