import { useState, useEffect } from "react";
import axios from "axios";

const useFetchLive = () => {
  const [loading, setLoading] = useState(true);
  const [live, setLive] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    const fetchLiveStatus = async () => {
      try {
        setLoading(true);

        const response = await axios.get("https://hallo.ubermorgen.info/api/nowplaying", {
          signal: controller.signal,
        });

        const data = response.data[0]; // Erster Eintrag der API-Antwort
        const isLive = data.live?.is_live; // Live-Status aus `live.is_live`
        const nowPlaying = data.now_playing;

        // Prüfen, ob etwas abgespielt wird
        const isStreamActive =
          isLive ||
          (nowPlaying?.played_at > 0 && nowPlaying?.remaining > 0);

        setLive(isStreamActive);
        setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error("Error fetching live status:", err);
          setError(err);
        }
        setLoading(false);
      }
    };

    fetchLiveStatus();

    return () => {
      controller.abort();
    };
  }, []);

  return [live, loading, error];
};

export default useFetchLive;